// formId: '646b31c4-03b9-48bc-bcc6-048771d5caf9',
// target: '#hubspotNlForm',

import React, { useEffect, useState } from 'react';

const NewsLetterForm = ({ portalId, formId, cssClass }) => {
  const [submitSucess, setSubmitSucess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: portalId,
      formId: formId,
      target: `#newsLetterFormId-${formId}`,
      cssClass: cssClass ? cssClass : '',
      redirectUrl: '/ty/hallucinationindex',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      event.data.id === formId
    ) {
      setSubmitSucess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className="mt-3">
      {submitSucess ? (
        <p>Thank you for subscribing to our newsletter!</p>
      ) : (
        <div id={`newsLetterFormId-${formId}`} />
      )}
    </div>
  );
};

export default NewsLetterForm;
